<template lang="pug">
span
  tooltipBtn(v-if="booked==false")
    template(v-slot:content)
      slot(name="addToShelf" :addBookmark="addBookmark")
        v-btn(
          rounded
          small,
          :loading="loading1"
          @click="addBookmark(bookId)",
          color="tertiary",
          :tile="$vuetify.breakpoint.xs && $route.name == 'Book'"
          )
          v-icon(v-if="$route.name == 'Search'") mdi-book-plus
          span(v-else) Add to shelf
    template(v-slot:tooltip-content) Add this book to your shelf

  span(v-else-if="booked==true")
    tooltipBtn(v-if="$route.name == 'Book-item'")
      template(v-slot:content)
        v-btn(
              @click="deleteDialog = true",
              color="error",
              small,
              :loading="loading1"
              rounded
            ) Remove from shelf
      template(v-slot:tooltip-content) Remove book from your shelf
      
    tooltipBtn(v-else)
      template(v-slot:content)
        v-btn(
          rounded
          small,
          color="teal"
          dark
          to="../user/shelf",
          :tile="$vuetify.breakpoint.xs && $route.name == 'Book'"
          ) On shelf
      template(v-slot:tooltip-content) Go to the shelf page

  v-btn(
        v-else
        rounded
        small,
        :loading="true"
        )
        v-icon(v-if="$route.name == 'Search'") mdi-book
        span(v-else) Loading

  tooltipBtn(v-if="$route.name != 'Book'")
    template(v-slot:content)
      a(:href="'/book/' + bookId" target="_blank")
        v-btn(
            rounded
            small,
            color="primary",
            ).ml-3
          v-icon(v-if="$route.name == 'Search'") mdi-book-arrow-right
          span(v-else) View book
    template(v-slot:tooltip-content) View book

  v-dialog(v-model="deleteDialog", width="600")
          v-card
            v-card-title.headline.grey.lighten-2 Remove Book
            .pa-5
              p.font-weight-light Are you sure that you want to remove the book titled <strong>{{ bookTitle }}</strong> from your shelf?
            v-divider
            v-card-actions.px-5.pb-5
              v-spacer
              v-btn(color="grey", text, @click="deleteDialog = false") Cancel
              v-btn(
                color="error",
                @click="removeBookmark(bookId)",
                :loading="loading1"
              ) Remove
  v-dialog(v-model="caution", width="600")
          v-card
            v-card-title.headline.grey.lighten-2 Shelf is Full
            .pa-5
              p.font-weight-light The maximum capacity for your shelf is only <strong>50 books</strong>, try to remove some books.
            v-divider
            v-card-actions.px-5.pb-5
              v-spacer
              v-btn(color="grey", text, @click="caution = false").mr-2 Close
              v-btn(:to="{name: 'Shelf'}" color="teal" dark) Go to Shelf
</template>
<script>
import tooltipBtn from "../components/TooltipBtn.vue";

export default {
  name: "AddtoShelf",
  components: { tooltipBtn },
  props: ["bookId", "bookTitle"],
  data() {
    return {
      userId: "",
      loading: false,
      loading2: false,
      deleteDialog: false,
      caution: false,
      shelf: [],
    };
  },
  computed: {
    bookmarks() {
      let book = this.$store.state.userInfo.bookmarks;
      return book ? book : ".";
    },
    booked() {
      return this.bookmarks.includes(this.bookId);
    },
    loading1: {
      get() {
        return this.$store.state.loading;
      },
      set(payload) {
        this.$store.commit("updateLoading", payload);
      },
    },
  },

  methods: {
    addBookmark(book_id) {
      if (this.$store.state.userInfo.bookmarks.length < 50) {
        this.$store.dispatch("addToShelf", book_id);
      } else {
        this.caution = true;
      }
    },
    removeBookmark(book_id) {
      this.$store.dispatch("removeFromShelf", book_id);
      this.deleteDialog = false;
    },
  },
};
</script>
